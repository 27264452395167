/* eslint-disable import/prefer-default-export */
import { isValid } from "date-fns";
import { formatInTimeZone } from "date-fns-tz";

export const formatDate = (date?: string | number | Date): string => {
  const parsedDate = date && (date instanceof Date ? date : new Date(date));

  if (!parsedDate || !isValid(parsedDate)) {
    return "";
  }

  return formatInTimeZone(parsedDate, "UTC", "MM/dd/yyyy");
};
