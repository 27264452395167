import styled from "@emotion/styled";
import UtilizeSpacer from "@/styles/theme-styles";
import defaultTheme from "@/styles/themes/default";

// Theme constants
const {
  colors: { grey600, black },
} = defaultTheme.renewTheme;

export const ModalContentWrapper = styled.div`
  text-align: left;
  color: ${grey600};
  ${UtilizeSpacer({
    specificOption: {
      size: "xs",
      direction: "top",
    },
    type: "margin",
  })}
`;

export const RequestItem = styled.div`
  ${UtilizeSpacer({
    specificOption: {
      size: "3xs",
      direction: "bottom",
    },
    type: "margin",
  })}

  span {
    color: ${black};
  }
`;
