export type Maybe<T> = T | null;
export type Exact<T extends { [key: string]: unknown }> = {
  [K in keyof T]: T[K];
};
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & {
  [SubKey in K]?: Maybe<T[SubKey]>;
};
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & {
  [SubKey in K]: Maybe<T[SubKey]>;
};
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  Datetime: string;
  JSON: Record<string, unknown>;
  MetricValue: string | number;
  URL: string;
};

export type ActionItem = {
  __typename?: "ActionItem";
  id: Scalars["ID"];
  renewalOffer?: Maybe<RenewalOffer>;
  type?: Maybe<ActionItemType>;
};

export type ActionItemResponse = {
  __typename?: "ActionItemResponse";
  actionItems: Array<ActionItem>;
  totalCount: Scalars["Int"];
};

export enum ActionItemType {
  EsignedNoticeToVacate = "ESIGNED_NOTICE_TO_VACATE",
  NeedSomethingElse = "NEED_SOMETHING_ELSE",
}

export type Address = {
  __typename?: "Address";
  city: Scalars["String"];
  id: Scalars["ID"];
  secondary?: Maybe<Scalars["String"]>;
  state: Scalars["String"];
  street: Scalars["String"];
  zipCode: Scalars["String"];
};

export type Charge = {
  __typename?: "Charge";
  amount: Scalars["Float"];
  frequency: ChargeFrequency;
  id: Scalars["ID"];
  name: Scalars["String"];
  type: ChargeType;
};

export enum ChargeFrequency {
  Annually = "ANNUALLY",
  Daily = "DAILY",
  Monthly = "MONTHLY",
  OneTime = "ONE_TIME",
  Quarterly = "QUARTERLY",
  Weekly = "WEEKLY",
}

export enum ChargeType {
  Discount = "DISCOUNT",
  Fee = "FEE",
  RentableItem = "RENTABLE_ITEM",
}

export type Community = {
  __typename?: "Community";
  id: Scalars["ID"];
  name: Scalars["String"];
};

export type Document = {
  __typename?: "Document";
  createdAt: Scalars["Datetime"];
  id: Scalars["ID"];
  type: DocumentType;
  uploadedBy?: Maybe<Scalars["String"]>;
  url: Scalars["URL"];
};

export enum DocumentType {
  EsignedNoticeToVacate = "ESIGNED_NOTICE_TO_VACATE",
  RenewalOffer = "RENEWAL_OFFER",
  RentersInsurance = "RENTERS_INSURANCE",
}

export type DownloadablePdfUrl = {
  __typename?: "DownloadablePdfUrl";
  url?: Maybe<Scalars["URL"]>;
};

export type DownloadablePdfUrlResponse = DownloadablePdfUrl | Error;

export type ESignature = {
  __typename?: "ESignature";
  id: Scalars["ID"];
  status: ESignatureStatus;
  type: ESignatureType;
};

export enum ESignatureStatus {
  AllResidentsSigned = "ALL_RESIDENTS_SIGNED",
  FullyExecuted = "FULLY_EXECUTED",
  InitiatedNobodySigned = "INITIATED_NOBODY_SIGNED",
  SomeResidentsSigned = "SOME_RESIDENTS_SIGNED",
  Voided = "VOIDED",
}

export enum ESignatureType {
  Ntv = "NTV",
}

export type EmbedUrl = {
  __typename?: "EmbedUrl";
  url: Scalars["URL"];
};

export type EmbedUrlResponse = EmbedUrl | Error;

export type Error = {
  __typename?: "Error";
  message: Scalars["String"];
};

export type ExternalRenewalOfferDecision = {
  __typename?: "ExternalRenewalOfferDecision";
  decidedAt?: Maybe<Scalars["Datetime"]>;
  decision: RenewalOfferDecisionType;
  id: Scalars["ID"];
  interestedTerm?: Maybe<ExternalRenewalOfferPricingTerm>;
  signedOnDate?: Maybe<Scalars["Datetime"]>;
};

export type ExternalRenewalOfferPricingTerm = {
  __typename?: "ExternalRenewalOfferPricingTerm";
  baseRent?: Maybe<Scalars["Float"]>;
  lengthInMonths?: Maybe<Scalars["Int"]>;
};

export type FutureLease = {
  __typename?: "FutureLease";
  signingStatus: ESignatureStatus;
};

export type InvalidReportEmbedTokenError = {
  __typename?: "InvalidReportEmbedTokenError";
  message: Scalars["String"];
};

export type Lease = {
  __typename?: "Lease";
  id: Scalars["ID"];
  moveOutInfo?: Maybe<MoveOutInfo>;
  noticeDate?: Maybe<Scalars["Datetime"]>;
  renewalOffer?: Maybe<RenewalOffer>;
  residentsInUnit: Array<Resident>;
  toDate?: Maybe<Scalars["Datetime"]>;
  unit: Unit;
  updatedResidentInfo?: Maybe<UpdatedResidentInfo>;
};

export type LikelihoodToRenew = {
  __typename?: "LikelihoodToRenew";
  residentName: Scalars["String"];
  value: Scalars["String"];
};

export type Metric = {
  __typename?: "Metric";
  icon: Scalars["String"];
  name: Scalars["String"];
  tooltipCopy?: Maybe<Scalars["String"]>;
  value: Scalars["MetricValue"];
};

export enum Month {
  April = "APRIL",
  August = "AUGUST",
  December = "DECEMBER",
  February = "FEBRUARY",
  January = "JANUARY",
  July = "JULY",
  June = "JUNE",
  March = "MARCH",
  May = "MAY",
  November = "NOVEMBER",
  October = "OCTOBER",
  September = "SEPTEMBER",
}

export type MoveOutInfo = {
  __typename?: "MoveOutInfo";
  forwardingAddress?: Maybe<Address>;
  forwardingAddressFirstName?: Maybe<Scalars["String"]>;
  forwardingAddressLastName?: Maybe<Scalars["String"]>;
  moveOutDate?: Maybe<Scalars["Datetime"]>;
  reasonForMoveOut: Scalars["String"];
  reasonForMoveOutAdditionalInfo?: Maybe<Scalars["String"]>;
};

export type Mutation = {
  __typename?: "Mutation";
  refreshNoticeToVacateESignature: RefreshNoticeToVacateESignatureResponse;
  renewalOfferBatchMergedPdfUrl?: Maybe<DownloadablePdfUrlResponse>;
  renewalOfferBatchZipUrl?: Maybe<DownloadablePdfUrlResponse>;
  resolveNeedSomethingElse: NeedSomethingElse;
  setEsignCounterSigner: SetEsignCounterSignerResponse;
};

export type MutationRefreshNoticeToVacateESignatureArgs = {
  input: RefreshNoticeToVacateESignatureInput;
};

export type MutationRenewalOfferBatchMergedPdfUrlArgs = {
  input: RenewalOfferBatchInput;
};

export type MutationRenewalOfferBatchZipUrlArgs = {
  input: RenewalOfferBatchInput;
};

export type MutationResolveNeedSomethingElseArgs = {
  input: ResolveNeedSomethingElseInput;
};

export type MutationSetEsignCounterSignerArgs = {
  input: SetEsignCounterSignerInput;
};

export type NeedSomethingElse = {
  __typename?: "NeedSomethingElse";
  additionalInfo: Scalars["String"];
  createdAt: Scalars["Datetime"];
  id: Scalars["ID"];
  needs: Array<Scalars["String"]>;
  preferredTerm?: Maybe<RenewalOfferPricingTerm>;
  resolutionNotes?: Maybe<Scalars["String"]>;
  resolvedAt?: Maybe<Scalars["Datetime"]>;
};

export type PropertyManager = {
  __typename?: "PropertyManager";
  id: Scalars["ID"];
  name: Scalars["String"];
};

export type Query = {
  __typename?: "Query";
  actionItems?: Maybe<ActionItemResponse>;
  activityLogReportEmbedToken?: Maybe<ReportEmbedTokenResponse>;
  downloadReportEmbedToken?: Maybe<ReportEmbedTokenResponse>;
  downloadablePdfUrl?: Maybe<DownloadablePdfUrlResponse>;
  eSignatureEmbedUrl?: Maybe<EmbedUrlResponse>;
  leases: Array<Lease>;
  renewalOfferBatch?: Maybe<RenewalOfferBatch>;
  renewalOfferBatches?: Maybe<RenewalOfferBatchesResponse>;
  renewalOffers: Array<RenewalOffer>;
  reportEmbedToken?: Maybe<ReportEmbedTokenResponse>;
  unit?: Maybe<Unit>;
  units?: Maybe<UnitsResponse>;
  user?: Maybe<User>;
};

export type QueryActionItemsArgs = {
  batchId: Scalars["ID"];
};

export type QueryActivityLogReportEmbedTokenArgs = {
  unit_id: Scalars["ID"];
};

export type QueryDownloadablePdfUrlArgs = {
  objectId: Scalars["ID"];
  objectType: DocumentType;
};

export type QueryESignatureEmbedUrlArgs = {
  esignedNoticeToVacateId: Scalars["ID"];
};

export type QueryLeasesArgs = {
  communityId: Scalars["ID"];
  excludeRecordsWithActiveOffers?: Maybe<Scalars["Boolean"]>;
  month: Month;
  year: Scalars["Int"];
};

export type QueryRenewalOfferBatchArgs = {
  batchId: Scalars["ID"];
};

export type QueryRenewalOffersArgs = {
  batchId: Scalars["ID"];
};

export type QueryUnitArgs = {
  id: Scalars["ID"];
};

export type QueryUnitsArgs = {
  communityId?: Maybe<Scalars["ID"]>;
};

export type RefreshNoticeToVacateESignatureInput = {
  eSignatureId: Scalars["ID"];
};

export type RefreshNoticeToVacateESignatureResponse = ESignature | Error;

export type RenewalOffer = {
  __typename?: "RenewalOffer";
  addedResidents: Scalars["Boolean"];
  createdAt: Scalars["Datetime"];
  decision?: Maybe<RenewalOfferDecisionResponse>;
  id: Scalars["ID"];
  lastReminderSentAt?: Maybe<Scalars["Datetime"]>;
  lastViewedAt?: Maybe<Scalars["Datetime"]>;
  lease?: Maybe<Lease>;
  likelihoodToRenew: Array<LikelihoodToRenew>;
  needSomethingElse: Array<NeedSomethingElse>;
  pdfUrl?: Maybe<Scalars["URL"]>;
  pricings: RenewalOfferPricings;
  removedResidents: Array<Resident>;
  residentActions: Array<ResidentAction>;
  status: RenewalOfferStatus;
  unitUpgrade?: Maybe<RenewalOfferUnitUpgrade>;
  workflowStatus: RenewalOfferWorkflowStatus;
};

export type RenewalOfferBatch = {
  __typename?: "RenewalOfferBatch";
  community: Community;
  id: Scalars["ID"];
  metrics: Array<Metric>;
  month: Scalars["String"];
  renewalOfferPdfsAvailable: Scalars["Boolean"];
  year: Scalars["String"];
};

export type RenewalOfferBatchInput = {
  renewalOfferBatchId: Scalars["ID"];
};

export type RenewalOfferBatchesPayload = {
  __typename?: "RenewalOfferBatchesPayload";
  renewalOfferBatches: Array<RenewalOfferBatch>;
};

export type RenewalOfferBatchesResponse = Error | RenewalOfferBatchesPayload;

export type RenewalOfferDecision = {
  __typename?: "RenewalOfferDecision";
  charges?: Maybe<Scalars["JSON"]>;
  decidedAt: Scalars["Datetime"];
  decidedByResident: Resident;
  decision: RenewalOfferDecisionType;
  eSignature?: Maybe<ESignature>;
  id: Scalars["ID"];
  interestedTerm?: Maybe<RenewalOfferPricingTerm>;
  pricing: RenewalOfferPricing;
  signedOnDate?: Maybe<Scalars["Datetime"]>;
};

export type RenewalOfferDecisionResponse =
  | ExternalRenewalOfferDecision
  | RenewalOfferDecision;

export enum RenewalOfferDecisionType {
  Declined = "DECLINED",
  Interested = "INTERESTED",
  InterestedLate = "INTERESTED_LATE",
}

export type RenewalOfferPricing = {
  __typename?: "RenewalOfferPricing";
  effectiveFrom: Scalars["Datetime"];
  id: Scalars["ID"];
  terms: Array<RenewalOfferPricingTerm>;
};

export type RenewalOfferPricingTerm = {
  __typename?: "RenewalOfferPricingTerm";
  baseRent: Scalars["Float"];
  charges?: Maybe<Array<Maybe<Charge>>>;
  id: Scalars["ID"];
  lengthInMonths: Scalars["Int"];
};

export type RenewalOfferPricings = {
  __typename?: "RenewalOfferPricings";
  current: RenewalOfferPricing;
  future?: Maybe<Array<Maybe<RenewalOfferPricing>>>;
  past?: Maybe<Array<Maybe<RenewalOfferPricing>>>;
};

export enum RenewalOfferStatus {
  Accepted = "ACCEPTED",
  Declined = "DECLINED",
  Interested = "INTERESTED",
  InterestedLate = "INTERESTED_LATE",
  NotOffered = "NOT_OFFERED",
  Offered = "OFFERED",
}

export type RenewalOfferUnitUpgrade = {
  __typename?: "RenewalOfferUnitUpgrade";
  createdAt: Scalars["Datetime"];
  id: Scalars["ID"];
  unit: Unit;
};

export enum RenewalOfferWorkflowStatus {
  AwaitingDecision = "AWAITING_DECISION",
  Completed = "COMPLETED",
  InProgress = "IN_PROGRESS",
  Sent = "SENT",
}

export type ReportEmbedToken = {
  __typename?: "ReportEmbedToken";
  token: Scalars["String"];
  url: Scalars["String"];
};

export type ReportEmbedTokenResponse =
  | InvalidReportEmbedTokenError
  | ReportEmbedToken;

export type Resident = {
  __typename?: "Resident";
  cellPhone?: Maybe<Scalars["String"]>;
  emailAddress?: Maybe<Scalars["String"]>;
  firstName: Scalars["String"];
  id: Scalars["ID"];
  lastName: Scalars["String"];
};

export type ResidentAction = {
  __typename?: "ResidentAction";
  name: Scalars["String"];
  occurredAt: Scalars["Datetime"];
};

export type ResolveNeedSomethingElseInput = {
  id: Scalars["ID"];
  notes: Scalars["String"];
};

export enum Role {
  Executive = "EXECUTIVE",
  Regional = "REGIONAL",
  Site = "SITE",
}

export type SetEsignCounterSignerInput = {
  eSignatureId: Scalars["ID"];
  email?: Maybe<Scalars["String"]>;
  firstName: Scalars["String"];
  lastName: Scalars["String"];
};

export type SetEsignCounterSignerResponse = {
  __typename?: "SetEsignCounterSignerResponse";
  success: Scalars["Boolean"];
};

export type Unit = {
  __typename?: "Unit";
  buildingName?: Maybe<Scalars["String"]>;
  community: Community;
  currentLease?: Maybe<Lease>;
  documents: Array<Document>;
  futureLease?: Maybe<FutureLease>;
  id: Scalars["ID"];
  marketingName: Scalars["String"];
};

export type UnitsError = {
  __typename?: "UnitsError";
  message: Scalars["String"];
};

export type UnitsPayload = {
  __typename?: "UnitsPayload";
  units: Array<Unit>;
};

export type UnitsResponse = UnitsError | UnitsPayload;

export type UpdatedResidentInfo = {
  __typename?: "UpdatedResidentInfo";
  additionalInfo?: Maybe<Scalars["String"]>;
  cellPhone?: Maybe<Scalars["String"]>;
  email: Scalars["String"];
  firstName: Scalars["String"];
  lastName: Scalars["String"];
  roommateInfo?: Maybe<Scalars["String"]>;
  updatedResidentInfoDiff?: Maybe<Scalars["JSON"]>;
};

export type User = {
  __typename?: "User";
  communities: Array<Community>;
  email: Scalars["String"];
  enabledFeatures: Array<Scalars["String"]>;
  id: Scalars["ID"];
  propertyManager: PropertyManager;
  role: Role;
};

export type ActionItemFragment = { __typename?: "ActionItem" } & Pick<
  ActionItem,
  "id" | "type"
> & {
    renewalOffer?: Maybe<
      { __typename?: "RenewalOffer" } & RenewalOfferFragment
    >;
  };

export type DocumentFragment = { __typename?: "Document" } & Pick<
  Document,
  "id" | "createdAt" | "type" | "uploadedBy" | "url"
>;

export type LeaseFragment = { __typename?: "Lease" } & Pick<
  Lease,
  "id" | "toDate"
> & {
    unit: { __typename?: "Unit" } & Pick<
      Unit,
      "id" | "marketingName" | "buildingName"
    > & {
        community: { __typename?: "Community" } & Pick<
          Community,
          "id" | "name"
        >;
      };
  };

export type UnitFragment = { __typename?: "Unit" } & Pick<
  Unit,
  "id" | "marketingName" | "buildingName"
> & {
    futureLease?: Maybe<
      { __typename?: "FutureLease" } & Pick<FutureLease, "signingStatus">
    >;
    community: { __typename?: "Community" } & Pick<Community, "id" | "name">;
    currentLease?: Maybe<
      { __typename?: "Lease" } & Pick<Lease, "id" | "toDate"> & {
          updatedResidentInfo?: Maybe<
            { __typename?: "UpdatedResidentInfo" } & Pick<
              UpdatedResidentInfo,
              | "firstName"
              | "lastName"
              | "email"
              | "roommateInfo"
              | "updatedResidentInfoDiff"
            >
          >;
          renewalOffer?: Maybe<
            { __typename?: "RenewalOffer" } & Pick<
              RenewalOffer,
              "id" | "status" | "workflowStatus" | "pdfUrl" | "addedResidents"
            > & {
                pricings: { __typename?: "RenewalOfferPricings" } & {
                  past?: Maybe<
                    Array<
                      Maybe<
                        { __typename?: "RenewalOfferPricing" } & Pick<
                          RenewalOfferPricing,
                          "id" | "effectiveFrom"
                        > & {
                            terms: Array<
                              { __typename?: "RenewalOfferPricingTerm" } & Pick<
                                RenewalOfferPricingTerm,
                                "id" | "lengthInMonths" | "baseRent"
                              > & {
                                  charges?: Maybe<
                                    Array<
                                      Maybe<
                                        { __typename?: "Charge" } & Pick<
                                          Charge,
                                          | "id"
                                          | "type"
                                          | "amount"
                                          | "name"
                                          | "frequency"
                                        >
                                      >
                                    >
                                  >;
                                }
                            >;
                          }
                      >
                    >
                  >;
                  current: { __typename?: "RenewalOfferPricing" } & Pick<
                    RenewalOfferPricing,
                    "id" | "effectiveFrom"
                  > & {
                      terms: Array<
                        { __typename?: "RenewalOfferPricingTerm" } & Pick<
                          RenewalOfferPricingTerm,
                          "id" | "lengthInMonths" | "baseRent"
                        > & {
                            charges?: Maybe<
                              Array<
                                Maybe<
                                  { __typename?: "Charge" } & Pick<
                                    Charge,
                                    | "id"
                                    | "type"
                                    | "amount"
                                    | "name"
                                    | "frequency"
                                  >
                                >
                              >
                            >;
                          }
                      >;
                    };
                  future?: Maybe<
                    Array<
                      Maybe<
                        { __typename?: "RenewalOfferPricing" } & Pick<
                          RenewalOfferPricing,
                          "id" | "effectiveFrom"
                        > & {
                            terms: Array<
                              { __typename?: "RenewalOfferPricingTerm" } & Pick<
                                RenewalOfferPricingTerm,
                                "id" | "lengthInMonths" | "baseRent"
                              > & {
                                  charges?: Maybe<
                                    Array<
                                      Maybe<
                                        { __typename?: "Charge" } & Pick<
                                          Charge,
                                          | "id"
                                          | "type"
                                          | "amount"
                                          | "name"
                                          | "frequency"
                                        >
                                      >
                                    >
                                  >;
                                }
                            >;
                          }
                      >
                    >
                  >;
                };
                needSomethingElse: Array<
                  { __typename?: "NeedSomethingElse" } & Pick<
                    NeedSomethingElse,
                    | "id"
                    | "needs"
                    | "resolvedAt"
                    | "createdAt"
                    | "resolutionNotes"
                    | "additionalInfo"
                  > & {
                      preferredTerm?: Maybe<
                        { __typename?: "RenewalOfferPricingTerm" } & Pick<
                          RenewalOfferPricingTerm,
                          "lengthInMonths"
                        >
                      >;
                    }
                >;
                decision?: Maybe<
                  | ({ __typename?: "ExternalRenewalOfferDecision" } & Pick<
                      ExternalRenewalOfferDecision,
                      "id" | "decision" | "signedOnDate"
                    > & {
                        externalDecidedAt: ExternalRenewalOfferDecision["decidedAt"];
                      } & {
                        externalInterestedTerm?: Maybe<
                          {
                            __typename?: "ExternalRenewalOfferPricingTerm";
                          } & Pick<
                            ExternalRenewalOfferPricingTerm,
                            "lengthInMonths" | "baseRent"
                          >
                        >;
                      })
                  | ({ __typename?: "RenewalOfferDecision" } & Pick<
                      RenewalOfferDecision,
                      "id" | "decision" | "charges" | "decidedAt"
                    > & {
                        decidedByResident: { __typename?: "Resident" } & Pick<
                          Resident,
                          "firstName" | "lastName" | "emailAddress"
                        >;
                        interestedTerm?: Maybe<
                          { __typename?: "RenewalOfferPricingTerm" } & Pick<
                            RenewalOfferPricingTerm,
                            "baseRent" | "lengthInMonths"
                          >
                        >;
                        eSignature?: Maybe<
                          { __typename?: "ESignature" } & Pick<
                            ESignature,
                            "id" | "status"
                          >
                        >;
                      })
                >;
                removedResidents: Array<
                  { __typename?: "Resident" } & Pick<
                    Resident,
                    | "id"
                    | "firstName"
                    | "lastName"
                    | "emailAddress"
                    | "cellPhone"
                  >
                >;
              }
          >;
          moveOutInfo?: Maybe<
            { __typename?: "MoveOutInfo" } & Pick<
              MoveOutInfo,
              | "reasonForMoveOut"
              | "moveOutDate"
              | "reasonForMoveOutAdditionalInfo"
              | "forwardingAddressFirstName"
              | "forwardingAddressLastName"
            > & {
                forwardingAddress?: Maybe<
                  { __typename?: "Address" } & Pick<
                    Address,
                    "state" | "street" | "secondary" | "zipCode" | "city"
                  >
                >;
              }
          >;
          residentsInUnit: Array<
            { __typename?: "Resident" } & Pick<
              Resident,
              "firstName" | "lastName" | "emailAddress" | "cellPhone"
            >
          >;
        }
    >;
    documents: Array<{ __typename?: "Document" } & DocumentFragment>;
  };

export type UnitSummaryFragment = { __typename?: "Unit" } & Pick<
  Unit,
  "id" | "marketingName" | "buildingName"
> & {
    futureLease?: Maybe<
      { __typename?: "FutureLease" } & Pick<FutureLease, "signingStatus">
    >;
    community: { __typename?: "Community" } & Pick<Community, "id" | "name">;
    currentLease?: Maybe<
      { __typename?: "Lease" } & Pick<Lease, "toDate"> & {
          renewalOffer?: Maybe<
            { __typename?: "RenewalOffer" } & Pick<
              RenewalOffer,
              "id" | "status" | "workflowStatus"
            > & {
                decision?: Maybe<
                  | ({ __typename?: "ExternalRenewalOfferDecision" } & Pick<
                      ExternalRenewalOfferDecision,
                      "id" | "decision"
                    >)
                  | ({ __typename?: "RenewalOfferDecision" } & Pick<
                      RenewalOfferDecision,
                      "id" | "decision"
                    > & {
                        eSignature?: Maybe<
                          { __typename?: "ESignature" } & Pick<
                            ESignature,
                            "id" | "status"
                          >
                        >;
                      })
                >;
              }
          >;
          moveOutInfo?: Maybe<
            { __typename?: "MoveOutInfo" } & Pick<MoveOutInfo, "moveOutDate">
          >;
          residentsInUnit: Array<
            { __typename?: "Resident" } & Pick<
              Resident,
              "firstName" | "lastName"
            >
          >;
        }
    >;
  };

export type UserFragment = { __typename?: "User" } & Pick<
  User,
  "id" | "role" | "email" | "enabledFeatures"
> & {
    communities: Array<
      { __typename?: "Community" } & Pick<Community, "id" | "name">
    >;
    propertyManager: { __typename?: "PropertyManager" } & Pick<
      PropertyManager,
      "id" | "name"
    >;
  };

export type RenewalOfferFragment = { __typename?: "RenewalOffer" } & Pick<
  RenewalOffer,
  | "id"
  | "createdAt"
  | "lastViewedAt"
  | "workflowStatus"
  | "lastReminderSentAt"
  | "pdfUrl"
> & {
    needSomethingElse: Array<
      { __typename?: "NeedSomethingElse" } & Pick<
        NeedSomethingElse,
        "id" | "createdAt" | "needs" | "additionalInfo" | "resolvedAt"
      >
    >;
    unitUpgrade?: Maybe<
      { __typename?: "RenewalOfferUnitUpgrade" } & Pick<
        RenewalOfferUnitUpgrade,
        "id" | "createdAt"
      > & {
          unit: { __typename?: "Unit" } & Pick<
            Unit,
            "id" | "marketingName" | "buildingName"
          >;
        }
    >;
    decision?: Maybe<
      | ({ __typename?: "ExternalRenewalOfferDecision" } & Pick<
          ExternalRenewalOfferDecision,
          "id" | "decision" | "signedOnDate"
        > & { externalDecidedAt: ExternalRenewalOfferDecision["decidedAt"] } & {
            externalInterestedTerm?: Maybe<
              { __typename?: "ExternalRenewalOfferPricingTerm" } & Pick<
                ExternalRenewalOfferPricingTerm,
                "lengthInMonths" | "baseRent"
              >
            >;
          })
      | ({ __typename?: "RenewalOfferDecision" } & Pick<
          RenewalOfferDecision,
          "id" | "decision" | "decidedAt" | "signedOnDate"
        > & {
            interestedTerm?: Maybe<
              { __typename?: "RenewalOfferPricingTerm" } & Pick<
                RenewalOfferPricingTerm,
                "lengthInMonths" | "baseRent"
              >
            >;
            eSignature?: Maybe<
              { __typename?: "ESignature" } & Pick<ESignature, "id" | "status">
            >;
          })
    >;
    residentActions: Array<
      { __typename?: "ResidentAction" } & Pick<
        ResidentAction,
        "occurredAt" | "name"
      >
    >;
    likelihoodToRenew: Array<
      { __typename?: "LikelihoodToRenew" } & Pick<
        LikelihoodToRenew,
        "residentName" | "value"
      >
    >;
    lease?: Maybe<
      { __typename?: "Lease" } & Pick<Lease, "id" | "noticeDate" | "toDate"> & {
          moveOutInfo?: Maybe<
            { __typename?: "MoveOutInfo" } & Pick<MoveOutInfo, "moveOutDate">
          >;
          unit: { __typename?: "Unit" } & Pick<
            Unit,
            "id" | "marketingName" | "buildingName"
          > & {
              community: { __typename?: "Community" } & Pick<
                Community,
                "id" | "name"
              >;
            };
        }
    >;
  };

export type RefreshNoticeToVacateESignatureMutationVariables = Exact<{
  eSignatureId: Scalars["ID"];
}>;

export type RefreshNoticeToVacateESignatureMutation = {
  __typename?: "Mutation";
} & {
  refreshNoticeToVacateESignature:
    | ({ __typename?: "ESignature" } & Pick<
        ESignature,
        "id" | "type" | "status"
      >)
    | { __typename?: "Error" };
};

export type RenewalOfferBatchMergedPdfUrlMutationVariables = Exact<{
  renewalOfferBatchId: Scalars["ID"];
}>;

export type RenewalOfferBatchMergedPdfUrlMutation = {
  __typename?: "Mutation";
} & {
  renewalOfferBatchMergedPdfUrl?: Maybe<
    | ({ __typename: "DownloadablePdfUrl" } & Pick<DownloadablePdfUrl, "url">)
    | { __typename?: "Error" }
  >;
};

export type RenewalOfferBatchZipUrlMutationVariables = Exact<{
  renewalOfferBatchId: Scalars["ID"];
}>;

export type RenewalOfferBatchZipUrlMutation = { __typename?: "Mutation" } & {
  renewalOfferBatchZipUrl?: Maybe<
    | ({ __typename: "DownloadablePdfUrl" } & Pick<DownloadablePdfUrl, "url">)
    | { __typename?: "Error" }
  >;
};

export type ResolveNeedSomethingElseMutationVariables = Exact<{
  resolveNeedSomethingElseInput: ResolveNeedSomethingElseInput;
}>;

export type ResolveNeedSomethingElseMutation = { __typename?: "Mutation" } & {
  resolveNeedSomethingElse: { __typename?: "NeedSomethingElse" } & Pick<
    NeedSomethingElse,
    "id" | "resolutionNotes" | "resolvedAt"
  >;
};

export type SetEsignCounterSignerMutationVariables = Exact<{
  setEsignCounterSignerInput: SetEsignCounterSignerInput;
}>;

export type SetEsignCounterSignerMutation = { __typename?: "Mutation" } & {
  setEsignCounterSigner: {
    __typename?: "SetEsignCounterSignerResponse";
  } & Pick<SetEsignCounterSignerResponse, "success">;
};

export type ActionItemsQueryVariables = Exact<{
  batchId: Scalars["ID"];
}>;

export type ActionItemsQuery = { __typename?: "Query" } & {
  actionItems?: Maybe<
    { __typename?: "ActionItemResponse" } & Pick<
      ActionItemResponse,
      "totalCount"
    > & {
        actionItems: Array<{ __typename?: "ActionItem" } & ActionItemFragment>;
      }
  >;
};

export type DownloadablePdfUrlQueryVariables = Exact<{
  objectId: Scalars["ID"];
  objectType: DocumentType;
}>;

export type DownloadablePdfUrlQuery = { __typename?: "Query" } & {
  downloadablePdfUrl?: Maybe<
    | ({ __typename: "DownloadablePdfUrl" } & Pick<DownloadablePdfUrl, "url">)
    | { __typename?: "Error" }
  >;
};

export type ESignatureEmbedUrlQueryVariables = Exact<{
  esignedNoticeToVacateId: Scalars["ID"];
}>;

export type ESignatureEmbedUrlQuery = { __typename?: "Query" } & {
  eSignatureEmbedUrl?: Maybe<
    | ({ __typename: "EmbedUrl" } & Pick<EmbedUrl, "url">)
    | { __typename?: "Error" }
  >;
};

export type LeasesQueryVariables = Exact<{
  month: Month;
  year: Scalars["Int"];
  communityId: Scalars["ID"];
  excludeRecordsWithActiveOffers: Scalars["Boolean"];
}>;

export type LeasesQuery = { __typename?: "Query" } & {
  leases: Array<
    { __typename?: "Lease" } & Pick<Lease, "id" | "toDate"> & {
        unit: { __typename?: "Unit" } & Pick<
          Unit,
          "id" | "marketingName" | "buildingName"
        > & {
            community: { __typename?: "Community" } & Pick<
              Community,
              "id" | "name"
            >;
          };
      }
  >;
};

export type RenewalOfferBatchQueryVariables = Exact<{
  batchId: Scalars["ID"];
}>;

export type RenewalOfferBatchQuery = { __typename?: "Query" } & {
  renewalOfferBatch?: Maybe<
    { __typename: "RenewalOfferBatch" } & Pick<RenewalOfferBatch, "id"> & {
        metrics: Array<
          { __typename?: "Metric" } & Pick<
            Metric,
            "name" | "value" | "icon" | "tooltipCopy"
          >
        >;
      }
  >;
};

export type RenewalOfferBatchesQueryVariables = Exact<{ [key: string]: never }>;

export type RenewalOfferBatchesQuery = { __typename?: "Query" } & {
  renewalOfferBatches?: Maybe<
    | { __typename?: "Error" }
    | ({ __typename?: "RenewalOfferBatchesPayload" } & {
        renewalOfferBatches: Array<
          { __typename: "RenewalOfferBatch" } & Pick<
            RenewalOfferBatch,
            "id" | "month" | "year" | "renewalOfferPdfsAvailable"
          > & {
              community: { __typename: "Community" } & Pick<
                Community,
                "id" | "name"
              >;
            }
        >;
      })
  >;
};

export type RenewalOffersQueryVariables = Exact<{
  batchId: Scalars["ID"];
}>;

export type RenewalOffersQuery = { __typename?: "Query" } & {
  renewalOffers: Array<{ __typename?: "RenewalOffer" } & RenewalOfferFragment>;
};

export type ActivityLogReportEmbedTokenQueryVariables = Exact<{
  unit_id: Scalars["ID"];
}>;

export type ActivityLogReportEmbedTokenQuery = { __typename?: "Query" } & {
  activityLogReportEmbedToken?: Maybe<
    | ({ __typename: "InvalidReportEmbedTokenError" } & Pick<
        InvalidReportEmbedTokenError,
        "message"
      >)
    | ({ __typename: "ReportEmbedToken" } & Pick<
        ReportEmbedToken,
        "token" | "url"
      >)
  >;
};

export type DownloadReportEmbedTokenQueryVariables = Exact<{
  [key: string]: never;
}>;

export type DownloadReportEmbedTokenQuery = { __typename?: "Query" } & {
  downloadReportEmbedToken?: Maybe<
    | ({ __typename: "InvalidReportEmbedTokenError" } & Pick<
        InvalidReportEmbedTokenError,
        "message"
      >)
    | ({ __typename: "ReportEmbedToken" } & Pick<
        ReportEmbedToken,
        "token" | "url"
      >)
  >;
};

export type ReportEmbedTokenQueryVariables = Exact<{ [key: string]: never }>;

export type ReportEmbedTokenQuery = { __typename?: "Query" } & {
  reportEmbedToken?: Maybe<
    | ({ __typename: "InvalidReportEmbedTokenError" } & Pick<
        InvalidReportEmbedTokenError,
        "message"
      >)
    | ({ __typename: "ReportEmbedToken" } & Pick<
        ReportEmbedToken,
        "token" | "url"
      >)
  >;
};

export type UnitQueryVariables = Exact<{
  id: Scalars["ID"];
}>;

export type UnitQuery = { __typename?: "Query" } & {
  unit?: Maybe<{ __typename?: "Unit" } & UnitFragment>;
};

export type UnitSummaryQueryVariables = Exact<{
  id: Scalars["ID"];
}>;

export type UnitSummaryQuery = { __typename?: "Query" } & {
  unit?: Maybe<{ __typename?: "Unit" } & UnitSummaryFragment>;
};

export type UnitsQueryVariables = Exact<{
  communityId: Scalars["ID"];
}>;

export type UnitsQuery = { __typename?: "Query" } & {
  units?: Maybe<
    | ({ __typename: "UnitsError" } & Pick<UnitsError, "message">)
    | ({ __typename: "UnitsPayload" } & {
        units: Array<{ __typename?: "Unit" } & UnitSummaryFragment>;
      })
  >;
};

export type GetUserQueryVariables = Exact<{ [key: string]: never }>;

export type GetUserQuery = { __typename?: "Query" } & {
  user?: Maybe<{ __typename?: "User" } & UserFragment>;
};
