import { Body1 } from "@/components/Typography";
import DefaultErrorIcon from "@mui/icons-material/ErrorOutline";
import DefaultSuccessIcon from "@mui/icons-material/CheckCircleOutline";
import DefaultInfoIcon from "@mui/icons-material/InfoOutlined";
import styled from "styled-components";
import defaultTheme from "@/styles/themes/default";

export const ToastContainer = styled.span`
  align-items: center;
  display: flex;
  width: 100%;
`;

export const SuccessIcon = styled(DefaultSuccessIcon)`
  color: ${defaultTheme.renewTheme.colors.night400};
  flex-shrink: 0;
  margin-right: ${defaultTheme.renewTheme.spacing.desktop.xs};
`;

export const InfoIcon = styled(DefaultInfoIcon)`
  color: ${defaultTheme.renewTheme.colors.warning100};
  flex-shrink: 0;
  margin-right: ${defaultTheme.renewTheme.spacing.desktop.xs};
`;

export const ErrorIcon = styled(DefaultErrorIcon)`
  color: ${defaultTheme.renewTheme.colors.error100};
  flex-shrink: 0;
  margin-right: ${defaultTheme.renewTheme.spacing.desktop.xs};
`;

export const Text = styled(Body1).attrs({ as: "span" })`
  margin-right: auto;
`;
