import ACTION_ITEM_FRAGMENT from "@/graphql/fragments/ActionItem";
import { ActionItemsQuery } from "@/graphql/generated";
import { TypedDocumentNode, gql } from "@apollo/client";

const GET_ACTION_ITEMS: TypedDocumentNode<ActionItemsQuery> = gql`
  query ActionItems($batchId: ID!) {
    actionItems(batchId: $batchId) {
      totalCount
      actionItems {
        ...ActionItem
      }
    }
  }
  ${ACTION_ITEM_FRAGMENT}
`;

export default GET_ACTION_ITEMS;
