import { RenewalOfferContext } from "@/context/renewal-offer/context";
import useResolveNeedSomethingElse from "@/graphql/hooks/resolveNeedSomethingElse/useResolveNeedSomethingElse";
import { useContext } from "react";

export default function useConnect() {
  const { renewalOfferState } = useContext(RenewalOfferContext);

  const { resolveNeedSomethingElse, loading: resolveLoading } =
    useResolveNeedSomethingElse(renewalOfferState?.batchId);
  return {
    resolveNeedSomethingElse,
    resolveLoading,
  };
}
