import { memo } from "react";

import * as Styled from "./styles";
import { IconProps, Props } from "./types";

function Icon({ variant }: IconProps): JSX.Element {
  switch (variant) {
    case "success":
      return <Styled.SuccessIcon />;
    case "error":
      return <Styled.ErrorIcon />;
    case "info":
      return <Styled.InfoIcon />;
    default:
      return <Styled.InfoIcon />;
  }
}

function Toast({
  className,
  id,
  message,
  variant = "info",
}: Props): JSX.Element {
  return (
    <Styled.ToastContainer className={className} id={id}>
      <Icon variant={variant} />
      <Styled.Text>{message}</Styled.Text>
    </Styled.ToastContainer>
  );
}

export default memo(Toast);
