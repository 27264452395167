import Toast from "@/components/Snackbar/Toast";
import toast from "react-hot-toast";

function showSnackbar(
  message: string,
  variant: "error" | "success" | "info" | undefined
): string {
  return toast((t) => <Toast message={message} variant={variant} id={t.id} />);
}

export default showSnackbar;
