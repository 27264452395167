import { DownloadablePdfUrlQuery } from "@/graphql/generated";

import { TypedDocumentNode, gql } from "@apollo/client";

const GET_DOWNLOAD_URL: TypedDocumentNode<DownloadablePdfUrlQuery> = gql`
  query DownloadablePdfUrl($objectId: ID!, $objectType: DocumentType!) {
    downloadablePdfUrl(objectId: $objectId, objectType: $objectType) {
      ... on DownloadablePdfUrl {
        __typename
        url
      }
    }
  }
`;

export default GET_DOWNLOAD_URL;
